import React from 'react'
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet"

import greenCheck from "../img/green-check.svg"
import redCheck from "../img/red-check.svg"
import Layout from "../components/Layout"
import HashtagHidden from "../components/HashtagHidden"

// eslint-disable-next-line
export const PricingSheetTemplate = ({
  features,
}) => {
  return (
    <>
      <section className="section section--gradient is-medium">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Plans & Pricing - Compensation Management Tools - Kamsa</title>
            <meta property="og:title" content="Plans & Pricing - Compensation Management Tools - Kamsa" />
            <meta name="description" content="Explore plans and pricing for Kamsa compensation management tools, including global salary data and personalized support from our compensation consultants." />
          </Helmet>
          <div className="block">
            <div className="columns">
              <div className="column">
                <h1 className="title is-h1">Find your plan</h1>
              </div>
            </div>
          </div>
          <div className="block table-container">
            <table className="table table--pricing is-bordered is-fullwidth">
              <thead>
                <tr>
                  { ['Features', 'Starter', 'Standard', 'Plus'].map((header) => {
                    const featuresOverride = header === 'Features'
                    return (
                      <th className={`is-vcentered ${featuresOverride ? 'has-text-left' : 'has-text-centered'}`} colSpan={featuresOverride ? 3 : 1}>
                        <h2 className="subtitle is-h2 mt-2 mb-4 px-4" style={{ lineHeight: '.8em', minWidth: '6.4em'}}>
                          <abbr className="no-underline nowrap" title={header}>{header}</abbr>
                        </h2>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                { features.map((feature, index) =>
                  <tr key={index}>
                    <td colSpan={3} className='is-vcentered'>
                      <div className='columns is-flex is-mobile is-vcentered'>
                        <div className='column is-2'>
                          <div className='block has-text-centered'>
                            <img alt={'Features'} className="features--icon" src={feature.icon.publicURL} />
                          </div>
                        </div>
                        <div className='column is-10'>
                          <div className="block">
                            <h2 className='has-text-weight-bold'>{feature.title}</h2>
                            <h2>{feature.description}</h2>
                          </div>
                        </div>
                      </div>
                    </td>
                    { feature.contact ? (
                      <td colSpan={3} className="has-text-centered is-vcentered">
                        <div className="block">
                          <h2 className="mt-2" style={{ marginBottom: '0.75rem' }}>Contact us for pricing</h2>
                        </div>
                      </td>
                      ) : (
                        <>
                          <td className="is-vcentered has-text-centered">
                            <div className='block'>
                              <div className="columns">
                                <div className="column pt-4">
                                  { feature.starter
                                    ? <img className="features--supported" src={greenCheck} alt="Included" />
                                    : <img className="features--supported" src={redCheck} alt="Not Included" /> }
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="is-vcentered has-text-centered">
                            <div className='block'>
                              <div className="columns">
                                <div className="column pt-4">
                                  { feature.standard
                                    ? <img className="features--supported" src={greenCheck} alt="Included" />
                                    : <img className="features--supported" src={redCheck} alt="Not Included" /> }
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="is-vcentered has-text-centered">
                            <div className='block'>
                              <div className="columns">
                                <div className="column pt-4">
                                  { feature.plus
                                    ? <img className="features--supported" src={greenCheck} alt="Included" />
                                    : <img className="features--supported" src={redCheck} alt="Not Included" /> }
                                </div>
                              </div>
                            </div>
                          </td>
                      </>
                    )}
                  </tr>
                )}
                <tr className="pricing-section">
                  <td colSpan={features.length} className="has-text-centered is-vcentered">
                    <div className="block" style={{ padding: '2em' }}>
                      <h2 className="subtitle is-4 mb-5">
                        Pricing varies based on employee size
                      </h2>
                      <a className="button is-large is-link" href="/request-demo">
                        Contact Us
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

PricingSheetTemplate.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    header: PropTypes.string,
    description: PropTypes.string,
    plus: PropTypes.bool,
    standard: PropTypes.bool,
    contact: PropTypes.bool,
  })),
};

const PricingSheet = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PricingSheetTemplate
        features={frontmatter.features}
      />
      <HashtagHidden hashtags={frontmatter.pricingPageHashtags} />
    </Layout>
  );
};

PricingSheet.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricingSheet;

export const pricingSheetQuery = graphql`
  query PricingSheet($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        features {
          icon {
            publicURL
          }
          title
          description
          plus
          standard
          starter
          contact
        }
        pricingPageHashtags {
          title
        }
      }
    }
  }
`;
